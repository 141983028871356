var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Customers"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Work Order Classifications","sub-heading":"Categorise your Work Orders for better reporting."}})],1),_c('column',{staticClass:"is-5"},[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search work order classifications..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.$route.query.search)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),_c('router-link',{staticClass:"button is-rounded is-flex",attrs:{"to":{
                  name: 'create-work-order-classification'
                }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Classification")])],1)],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.categories.data),function(classification){return _c('div',{key:classification.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{staticClass:"mr-2",attrs:{"to":{
                name: 'edit-work-order-classification',
                params: {
                  workOrderClassification: classification.uuid
                }
              }}},[_vm._v(" "+_vm._s(classification.title)+" ")]),_c('p',{staticClass:"has-text-grey"},[_c('small',[_vm._v("Updated "+_vm._s(_vm._f("asCalendar")(classification.updated_at)))]),(classification.segmented_teams_count)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(`This classification is only accessible by the segemented teams(${classification.segmented_teams_count}) it is assigned to.`),expression:"`This classification is only accessible by the segemented teams(${classification.segmented_teams_count}) it is assigned to.`"}],staticClass:"has-text-warning"},[_c('icon',{attrs:{"icon":"exclamation-triangle"}})],1):_vm._e()])],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"icon":"helmet-safety","label":"Work Orders","info":classification.work_orders_count}})],1)],1)],1)}),0),(_vm.categories.data.length)?_c('pager',{attrs:{"pageable":_vm.categories,"context":"Classification","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.categories.data.length,"with-link":"","heading":"There are no job categories available for display.","to":{
          name: 'create-work-order-classification'
        },"to-text":"New Classification"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }