<template>
  <loader v-bind="{ loading }" text="Loading Customers">
    <columns>
      <column>
        <page-heading
          heading="Work Order Classifications"
          sub-heading="Categorise your Work Orders for better reporting."
        />
      </column>
      <column class="is-5">
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search work order classifications...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button @submit="runSearch" class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="$route.query.search"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
                <router-link 
                  class="button is-rounded is-flex" 
                  :to="{
                    name: 'create-work-order-classification'
                  }">
                    <icon icon="plus"/>
                    <span>Classification</span>
                  </router-link>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>
    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="classification in categories.data" :key="classification.id">
            <columns>
              <column>
                <router-link class="mr-2" :to="{
                  name: 'edit-work-order-classification',
                  params: {
                    workOrderClassification: classification.uuid
                  }
                }">
                  {{ classification.title }}
                </router-link>
                <p class="has-text-grey">
                  <small>Updated {{ classification.updated_at | asCalendar }}</small>
                  <span 
                    v-tooltip="`This classification is only accessible by the segemented teams(${classification.segmented_teams_count}) it is assigned to.`"
                    class="has-text-warning" 
                    v-if="classification.segmented_teams_count">
                    <icon 
                      icon="exclamation-triangle"
                    />
                  </span>
                </p>
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <index-info 
                  icon="helmet-safety" 
                  label="Work Orders" 
                  :info="classification.work_orders_count"
                />
              </column>
            </columns>
          </div>
        </div>

      <pager v-if="categories.data.length" :pageable="categories" context="Classification" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="categories.data.length"
          with-link
          heading="There are no job categories available for display."
          :to="{
            name: 'create-work-order-classification'
          }"
          to-text="New Classification"
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
export default {

  data: () => ({
    loading: true,
    categories: {
      data: []
    },
    filters: {
      search: ''
    }
  }),

  created() {
    if (this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadCategories()
  },

  methods: {
    loadCategories() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.categories = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'work-order-classifications',
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        name: 'work-order-classifications',
      });
    }
  },

  watch: {
    '$route': 'loadCategories'
  }

}
</script>